// plansData.js
export const plansData = [
  {
    name: 'PLANO 1',
    priceMonthly: 'R$29,00',
    priceYearly: 'R$348,00',
    description: 'No credit card required',
    link: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/servidor-vps-amd-epyc-7502p-europa/plano-1', // Adicione o link aqui
    features: [
      'AMD Epyc 7502p',
      '2vCORE',
      '2GB DDR4',
      '30GB NVMe',
      '12Tbps Proteção Anti-Ddos',
    ],
  },
  {
    name: 'PLANO 2',
    priceMonthly: 'R$55,00',
    priceYearly: 'R$660,00',
    description: 'No credit card required',
    link: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/servidor-vps-amd-epyc-7502p-europa/plano-2-1', // Adicione o link aqui
    features: [
      'AMD Epyc 7502p',
      '2vCORE',
      '4GB DDR4',
      '65GB NVMe',
      '12Tbps Proteção Anti-Ddos',
    ],
  },
  {
    name: 'PLANO 3',
    priceMonthly: 'R$120,00',
    priceYearly: 'R$1.440,00',
    description: 'No credit card required',
    link: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/servidor-vps-amd-epyc-7502p-europa/plano-3-1', // Adicione o link aqui
    features: [
      'AMD Epyc 7502p',
      '4vCORE',
      '8GB DDR4',
      '90GB NVMe',
      '12Tbps Proteção Anti-Ddos',
    ],
  },
  {
    name: 'Personalizado',
    priceContact: 'Contate-nos',
    description: 'for custom offer',
    link: 'https://discord.tomadahost.cloud/', // Adicione o link aqui
    features: [
      'AMD Epyc 7502p',
      '6vCORE+',
      '12GB DDR4+',
      '120GB NVMe+',
      '12Tbps Proteção Anti-Ddos',
    ],
  },
];
