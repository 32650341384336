import React, { useState } from 'react';
import './Preço.css';

const planData = [
    {
        name: 'Terra',
        isPopular: false,
        img: 'https://storyblok.shockbyte.com/f/296405/24x24/e7cf9c9c36/dirt345345.svg',
        priceMonthly: 25,
        priceYearly: 300,
        includes: [
            { text: '1vCore (100%)', included: true },
            { text: '2GB Ram', included: true },
            { text: '15GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: false },
            { text: 'Plugins para minecraft', included: false },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/terra',
    },
    {
        name: 'Areia',
        isPopular: false,
        img: 'https://storyblok.shockbyte.com/f/296405/97x96/e49820d3eb/sand765754645.svg',
        priceMonthly: 40,
        priceYearly: 480,
        includes: [
            { text: '2vCore (200%)', included: true },
            { text: '4GB Ram', included: true },
            { text: '25GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: false },
            { text: 'Plugins para minecraft', included: false },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/areia',
    },
    {
        name: 'Pedra (Popular)',
        isPopular: true,
        img: 'https://images.minecraft-heads.com/render3d/head/3f/3fbbed193946da3b7cba953023b9e1a9.webp',
        priceMonthly: 55,
        priceYearly: 660,
        includes: [
            { text: '2vCore (200%)', included: true },
            { text: '6GB Ram', included: true },
            { text: '35GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: false },
            { text: 'Plugins para minecraft', included: false },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/pedra',
    },
    {
        name: 'Ferro',
        isPopular: false,
        img: 'https://storyblok.shockbyte.com/f/296405/150x150/c4117ce88b/iron011717427633657.svg',
        priceMonthly: 70,
        priceYearly: 840,
        includes: [
            { text: '3vCore (300%)', included: true },
            { text: '8GB Ram', included: true },
            { text: '50GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: true },
            { text: 'Plugins para minecraft', included: true },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/ferro',
    },
    {
        name: 'Ouro',
        isPopular: false,
        img: 'https://storyblok.shockbyte.com/f/296405/150x150/6a2003185d/gold011717427641934.svg',
        priceMonthly: 100,
        priceYearly: 1200,
        includes: [
            { text: '3vCore (300%)', included: true },
            { text: '12GB Ram', included: true },
            { text: '60GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: true },
            { text: 'Plugins para minecraft', included: true },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/ouro',
    },
    {
        name: 'Redstone',
        isPopular: false,
        img: 'https://storyblok.shockbyte.com/f/296405/150x150/a158ec004c/redstone011717427650581.svg',
        priceMonthly: 125,
        priceYearly: 1500,
        includes: [
            { text: '3vCore (300%)', included: true },
            { text: '16GB Ram', included: true },
            { text: '75GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: true },
            { text: 'Plugins para minecraft', included: true },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/redstone',
    },
];

const PricingSection = () => {
    const [toggle, setToggle] = useState(false);

    return (
        <section className="relative py-20 overflow-hidden" style={{ marginTop: '60px' }}>
            <div className="relative container px-4 mx-auto">
                <div className="max-w-2xl lg:max-w-5xl mx-auto mb-24 text-center">
                    <h1 className="font-heading text-4xl sm:text-6xl lg:text-7xl font-bold text-gray-900 mb-8">
                        <span>Escolha um plano para o seu servidor! </span>
                    </h1>
                    <div className="flex items-center justify-center">
                        <div
                            onClick={() => setToggle(!toggle)}
                            className="relative inline-flex p-1 w-12 h-7 items-center justify-end bg-gray-100 rounded-full cursor-pointer"
                        >
                            <span
                                className={`absolute left-1 w-5 h-5 bg-orange-900 rounded-full transition-all duration-500 ${toggle ? 'left-1/2' : 'left-1'
                                    }`}
                            ></span>
                        </div>
                        <span className={`ml-3 text-xl text-gray-900 ${!toggle ? 'block' : 'hidden'}`}>
                            Preço mensal (20%)
                        </span>
                        <span className={`ml-3 text-xl text-gray-900 ${toggle ? 'block' : 'hidden'}`}>
                            Preço anual (20%)
                        </span>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto">
                    <div className="flex flex-wrap -mx-4">
                        {planData.map((plan, index) => (
                            <div
                                key={index}
                                className={`w-full lg:w-1/3 px-4 ${plan.isPopular ? 'mb-8 lg:mb-0' : 'lg:pt-16'}`}
                            >
                                <div
                                    className={`max-w-sm mx-auto p-10 ${plan.isPopular ? 'bg-gray-900' : 'bg-gray-50 border border-gray-100'
                                        } rounded-3xl`}
                                >
                                    <div
                                        className={`flex mb-4 items-center justify-center w-10 h-10 ${plan.isPopular ? 'bg-orange-900' : 'bg-gray-100'
                                            } rounded-full`}
                                    >
                                        <img src={plan.img} alt="" style={{ width: "24px"}}/>
                                    </div>
                                    <span
                                        className={`block text-2xl font-semibold ${plan.isPopular ? 'text-white' : 'text-gray-900'
                                            } mb-2`}
                                    >
                                        {plan.name}
                                    </span>
                                    <span className="flex items-end">
                                        {toggle ? (
                                            <>
                                                <span
                                                    className={`text-5xl font-extrabold ${plan.isPopular ? 'text-white' : 'text-gray-900'
                                                        } leading-none`}
                                                >
                                                    R${plan.priceYearly},00
                                                </span>
                                                <span className={`text-${plan.isPopular ? 'gray-50' : 'gray-400'}`}>
                                                    /ano
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <span
                                                    className={`text-5xl font-extrabold ${plan.isPopular ? 'text-white' : 'text-gray-900'
                                                        } leading-none`}
                                                >
                                                    R${plan.priceMonthly},00
                                                </span>
                                                <span className={`text-${plan.isPopular ? 'gray-50' : 'gray-400'}`}>
                                                    /mês
                                                </span>
                                            </>
                                        )}
                                    </span>
                                    <div className="mt-6">
                                        <span className={`block font-bold mb-4 ${plan.isPopular ? 'text-white' : 'text-gray-900'}`}>
                                            O que está incluído:
                                        </span>
                                        <ul className="mb-10">
                                            {plan.includes.map((item, idx) => (
                                                <li key={idx} className="flex mb-6 items-center">
                                                    <img
                                                        src={
                                                            item.included
                                                                ? 'https://static.shuffle.dev/components/preview/2f808e47-944e-42cf-b821-2358251e0600/assets/public/saturn-assets/images/pricing/green-check.svg'
                                                                : 'https://static.shuffle.dev/components/preview/2f808e47-944e-42cf-b821-2358251e0600/assets/public/saturn-assets/images/pricing/check-circle-grey.svg'
                                                        }
                                                        alt=""
                                                    />
                                                    <span
                                                        className={`ml-2 text-sm ${item.included
                                                                ? plan.isPopular
                                                                    ? 'text-white'
                                                                    : 'text-gray-900'
                                                                : 'text-gray-500 line-through'
                                                            }`}
                                                    >
                                                        {item.text}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                        <a
                                            className={`relative group inline-block py-4 px-7 text-sm text-center ${plan.isPopular ? 'text-orange-50 hover:texto-white' : 'text-orange-900 hover:text-orange-50'
                                                } font-semibold ${plan.isPopular ? 'bg-orange-900' : 'border border-gray-200'} rounded-full overflow-hidden transition duration-200`}
                                            href={plan.redirectLink}
                                        >
                                            <div className="absolute top-0 right-full w-full h-full bg-orange-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500" style={{ border: "5px solid #fffff", borderRadius: "50px"}}></div>
                                            <span className="relative">Escolher Plano</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PricingSection;
